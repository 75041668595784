<template>
  <div>
    <v-card flat class="d-flex align-center rounded-lg pa-4">
      <div class="mr-4">
        <!-- <v-btn depressed class="mr-4" @click="setToday">
          Today
        </v-btn> -->
        <v-btn icon @click="setCalendarMonth('previous')">
          <v-icon>mdi-skip-previous</v-icon>
        </v-btn>
        <v-btn icon @click="setCalendarMonth('next')">
          <v-icon>mdi-skip-next</v-icon>
        </v-btn>
      </div>
      <span class="text-h6 text-color-main font-weight-black">
        {{ dateRangeTitle }}
      </span>
      <v-spacer></v-spacer>
      <v-btn-toggle
        v-model="selectedCalendarType"
        mandatory
      >
        <v-btn
          v-for="(calendarType, key) in calendarTypes"
          :key="key"
          @click="$store.commit('eventModule/SET_CALENDAR_TYPE', calendarType.value)"
        >
          {{ calendarType.text }}
        </v-btn>
      </v-btn-toggle>
    </v-card>
  </div>
</template>

<script>
import { EVENT_CALENDAR_COMPONENT_EVENT_BUS } from "./js/eventCalendarComponentEventBus";

import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("eventModule");

import moment from "moment";

export default {
  data: () => ({
    calendarTypes: [
      { text: "Month", value: "month" },
      { text: "Week", value: "week" },
      { text: "Day", value: "day" }
    ]
  }),
  computed: {
    ...mapGetters({
      now: 'now',
      calendarType: 'calendarType'
    }),
    selectedCalendarType: function () {
      let index = this.calendarTypes.findIndex(item => item.value == this.calendarType);

      if (index > -1) {
        return index;
      }

      return 0;
    },
    dateRangeTitle: function() {
      let calendarType = this.calendarType;
      let now = this.now;

        if (calendarType === "week") {
          const fromDate = now.startOf("week").format("MMMM DD, YYYY");
          const toDate = now.endOf("week").format("MMMM DD, YYYY");

          return `${fromDate} - ${toDate}`;
        }

        if (calendarType === "day") {
          return now.format("MMMM DD, YYYY");
        }

        //month
        return now.format("MMMM, YYYY");
    }
  },
  methods: {
    setCalendarMonth(action) {
      if (action === "previous") {
        EVENT_CALENDAR_COMPONENT_EVENT_BUS.$emit("previousMonth");

        this.setDate("subtract");
        return;
      }

      if (action === "next") {
        EVENT_CALENDAR_COMPONENT_EVENT_BUS.$emit("nextMonth");

        this.setDate("add");
        return;
      }
    },
    setDate(action) {
      let calendarType = this.calendarType;

      if (calendarType === "month") {
        this.now[action](1, "months");
      }

      if (calendarType === "week") {
        this.now[action](7, "days");
      }

      if (calendarType === "day") {
        this.now[action](1, "days");
      }
    },
    setToday() {
      this.$store.commit('eventModule/SET_NOW', moment());
      // this.selectedCalendarType = 'day'
    }
  }
};
</script>
